import { createWithEqualityFn } from "zustand/traditional";

type MainStoreInit = {
    mainSteps   : Array<boolean>;
    currentStep : number;
    refLocation : string;
    serverPath  : string;
    assetsPath  : string;
    publicPath  : string;
};

type MainStoreActions = {
    setMainSteps    : (mainSteps : Array<boolean>) => void;
    getMainSteps    : () => Array<boolean>;
    setCurrentStep  : (currentStep : number) => void;
    getCurrentStep  : () => number;
    setRefLocation  : (refLocation : string) => void;
    getRefLocation  : () => string;
    setServerPath   : (serverPath : string) => void;
    getServerPath   : () => string;
    setAssetsPath   : (assetsPath : string) => void;
    getAssetsPath   : () => string;
    setPublicPath   : (publicPath : string) => void;
    getPublicPath   : () => string;

    initLocation    : () => void;
    reset           : () => void;
};


const initialState: MainStoreInit = {
    mainSteps   : [true],
    currentStep : 0,
    refLocation : "DEV",
    serverPath  : "",
    assetsPath  : "",
    publicPath  : "",
};

export const useMainStore = createWithEqualityFn<MainStoreInit & MainStoreActions>((set, get) => ({
    ...initialState,

    setMainSteps: (mainSteps : Array<boolean>) => set({ mainSteps: mainSteps }),
    getMainSteps: () => get().mainSteps,

    setCurrentStep: (currentStep : number) => {
        const mainSteps = get().mainSteps;
        mainSteps[get().currentStep] = false;
        set({ mainSteps: mainSteps });
        mainSteps[currentStep] = true;
        set({ mainSteps: mainSteps });
        set({ currentStep: currentStep });

    },
    getCurrentStep: () => get().currentStep,

    setRefLocation: (refLocation : string) => set({ refLocation: refLocation }),
    getRefLocation: () => get().refLocation,

    setServerPath: (serverPath : string) => set({ serverPath: serverPath }),
    getServerPath: () => get().serverPath,

    setAssetsPath: (assetsPath : string) => set({ assetsPath: assetsPath }),
    getAssetsPath: () => get().assetsPath,

    setPublicPath: (publicPath : string) => set({ publicPath: publicPath }),
    getPublicPath: () => get().publicPath,

    initLocation: () => {
        const refLocation = (window.location.href.includes("test")) ? "DEV" : "PROD";
        useMainStore.getState().setRefLocation(refLocation);
        const currentUrl = window.location.origin;
        
        switch (refLocation) {
            case "DEV":
                useMainStore.getState().setServerPath("https://mssports-v4.test/views-sports/public/services/request.php");
                useMainStore.getState().setAssetsPath("https://mssports-v4.test/views-sports/src/assets");
                useMainStore.getState().setPublicPath("https://mssports-v4.test/views-sports/public");
                break;
            /*case "PROD":
                useMainStore.getState().setServerPath("https://mssports.online/services/request.php");
                useMainStore.getState().setAssetsPath("https://mssports.online/src/assets");
                useMainStore.getState().setPublicPath("https://mssports.online");
                break;*/
            case "PROD":
                useMainStore.getState().setServerPath(currentUrl + "/services/request.php");
                useMainStore.getState().setAssetsPath(currentUrl + "/src/assets");
                useMainStore.getState().setPublicPath(currentUrl);
                break;
        }
    },

    reset: () => set(initialState),
}),Object.is);