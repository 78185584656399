import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { ModalsProvider} from '@mantine/modals';

import { useMainStore } from './components/stores/main.store';
import { lazy, Suspense } from 'react';
import ConnexionEnCours from './components/initialisation/ConnexionEnCours';
import { Notifications } from '@mantine/notifications';

const Accueil = lazy(() => import('./components/accueil/Accueil'));
const SplashScreen = lazy(() => import('./components/initialisation/SplashScreen'));
const MainBoard = lazy(() => import('./components/mainBoard/MainBoard'));
const SelectGround = lazy(() => import('./components/mainBoard/MainGround'));
const PreloadInterface = lazy(() => import('./components/mainBoard/PreloadInterface'));
const Interface = lazy(() => import('./components/mainInterface/Interface'));
const Identifiants = lazy(() => import('./components/accueil/Identifiants'));


export default function App() {

  const [mainSteps] = useMainStore((state) => [state.mainSteps]);

  const theme : MantineThemeOverride = {
    loader: 'bars',
    colorScheme: 'dark',
  };
 
    
  return (
    <MantineProvider theme={theme}>
      <ModalsProvider>
        <Notifications />
          {mainSteps[0] && 
            <Suspense fallback={<ConnexionEnCours/>}>
              < SplashScreen />
            </Suspense>
          }
          {mainSteps[1] && 
            <Suspense fallback={<ConnexionEnCours/>}>
              < Accueil />
            </Suspense>
          }
          { mainSteps[12] &&
            <Suspense fallback={<ConnexionEnCours/>}>
              <Identifiants />
            </Suspense>
          }
          {mainSteps[2] && 
            <Suspense fallback={<ConnexionEnCours/>}>
                < MainBoard />
            </Suspense>
          }
          {mainSteps[3] && 
            <Suspense fallback={<ConnexionEnCours/>}>
              < SelectGround />
            </Suspense>
          }
          {mainSteps[4] && 
            <Suspense fallback={<ConnexionEnCours/>}>
              < PreloadInterface />
            </Suspense>
          }
          {mainSteps[5] && 
            <Suspense fallback={<ConnexionEnCours/>}>
              <Interface />
            </Suspense>
          }
      </ModalsProvider>
    </MantineProvider>
  )
}
